import React from "react";
import Content from "../components/home/Content";

export default function Home() {
  return (
    <div>
      <Content></Content>
    </div>
  );
}
